

import {Component, Mixins, Watch} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import DilekceForm from "@/components/forms/DilekceForm.vue";
import {DilekceEntity} from "@/entity/DilekceEntity";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {DilekceTipiLabel} from "@/enum/DilekceTipi";
import DilekceTipPicker from "@/components/pickers/DilekceTipPicker.vue";
import SayfaAciklama from "@/components/SayfaAciklama.vue";

@Component({
  components: {SayfaAciklama, DilekceTipPicker, DilekceForm, FormDialog, DeleteDialog}
})

export default class DilekceList extends Mixins(SimpleInputMixin) {
  localValue!: Array<DilekceEntity>;
  searchText: string = "";
  dilekceTipi: string = "";
  dilekceTipiLabel = DilekceTipiLabel;
  action = "/api/v1/dilekce"
  oldAction = "";

  headers = [
    {text: "Favori", width: 50, sortable: true, value: "favori_mi"},
    {text: "Dilekçe Adı", align: "start", sortable: true, value: "isim"},
    {text: "Dilekçe Tipi", sortable: true, value: "dilekce_tipi"},
    {text: "Oluşturulma Tarihi", sortable: true, value: "created_at"},
    {text: "İşlemler", value: "actions", width: 150, align: "end",sortable: false}
  ];

  @Watch("searchText")
  onSearchTextChange() {
    setTimeout(() => {
      if (this.searchText && this.searchText != "")
        this.action = "/api/v1/dilekce/filter?isim=" + this.searchText;
      else
        this.onDilekceTipiChange();
      this.load();
    }, 750);
  }

  @Watch('dilekceTipi')
  onDilekceTipiChange() {
    if (this.dilekceTipi)
      this.action = "/api/v1/dilekce/filter?dilekceTipi=" + this.dilekceTipi;
    else if (this.searchText && this.searchText != "")
      this.onSearchTextChange();
    this.load();
  }

  mounted() {
    if (!this.value) {
      this.localValue = []
    }
    this.load();
  }

  onFavoriChange(item: DilekceEntity){
    item.favori_mi=!item.favori_mi;
    this.$http.put("/api/v1/dilekce/"+item.id, item).then(()=>{
      this.load(true);
    });
  }

  load(zorunluYenileme: boolean=false) {
    if (this.searchText && this.searchText != "" && this.dilekceTipi) {
      this.action = "/api/v1/dilekce/filter?isim=" + this.searchText + "&dilekceTipi=" + this.dilekceTipi;
    } else if (this.searchText == "" && !this.dilekceTipi) {
      this.action = "/api/v1/dilekce";
    }
    if (this.oldAction != this.action || zorunluYenileme) {
      this.$http.get(this.action)
          .then(response => {
            this.localValue = response.data;
          });
      this.oldAction = this.action
    }
  }
}
